// PAGES

.sub_page {
    padding-top: 4rem;

    @include breakpoint(tablet) {
        padding-top: 14rem;
    }
}

#sub {
    h3 {
        margin-bottom: 1rem;
        font-size: 24px;
    }
    h4 {
        margin-bottom: 1rem;
    }

    main {
        p {
            margin-bottom: 2rem;
        }

        .text_box {
            max-width: 760px;
        }
    }
}