::selection {
    background-color: rgba($color: $c1, $alpha: .9);
    color: white;
  }

html {
    background-color: $c3;
    font-family: $main-font;
    line-height: 1.4;
    scroll-behavior: smooth;
}

body {
    color: $c1;

    strong {
        font-weight: 700;
        letter-spacing: 1px;
    }

    p {

        @include breakpoint(tablet) {
            font-size: rem(18px);
        }
    }

    a {
        color: $c1;
        font-weight: 600;
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: $h-font;
        color: $c1;
        text-align: center;
        margin-bottom: 3rem;
        transition: font-size .3s ease-in-out;
        font-weight: 700;
        position: relative;
        padding-left: 14px;
        padding-right: 14px;

        span {
            display: block;
            color: $c1;
        }
    }

    h1 {
        font-size: rem(24px);
        padding-top: 2rem;

        @include breakpoint(mobile) {
            font-size: rem(29px);
        }

        @include breakpoint(tablet) {
            font-size: rem(44px);
        }

        @include breakpoint(desktop) {
            font-size: rem(55px);
        }

        &:after {
            content: '';
            height: 6px;
            width: 100%;
            background: $c1;
            position: absolute;
            bottom: -16px;
            left: 0;
            right: 0;
            max-width: 280px;
            margin: 0 auto;

            @include breakpoint(tablet) {
                max-width: 380px;
                height: 10px;
            }
        }

        span {
            font-size: rem(17px);

            @include breakpoint(tablet) {
                line-height: rem(22px);
            }
    
            @include breakpoint(desktop) {
                font-size: rem(24px);
            }
    
            @include breakpoint(desktop-l) {
                line-height: rem(40px);
            }
    
            @include breakpoint(fullhd) {
                font-size: rem(26px);
                line-height: rem(50px);
            }
        }
    }
}