// VARIABLES

// colors
$c1: #00427f;
$c2: #ea6926;
$c3: #fdf6d7;
$c4: #303030;

$light: rgb(255, 255, 255);
$medium: #f1f1f1;
$dark: #303030;

// fonts
$h-font: "Poppins", sans-serif;
$main-font: "Poppins", sans-serif;


// abstand
$gap_1: 3rem;
$gap-1-m: 6rem;
$gap-1-l: 7rem;
$gap-1-xl: 9rem;

$gap_2: 7rem;


/* BREAKPOINT v.1.0 MS */
$breakpoints: (
  'default':            '',
  'mobile':             400px,
  'mobile_l':           480px,
  'tablet':             668px,
  'laptop':             1024px,
  'desktop':            1280px,
  'desktop-l':          1680px,
  'fullhd':             1920px,
  'qhd':                2440px,
  'uhd':                4880px,
);

// min or max
$media-direction:       min;  // min / max — default is min (mobile first)

// Mixin
@mixin breakpoint($breakpoint,$direction: $media-direction) {

  // Es wird gecheckt ob der Wert in der Sass Map vorhanden ist
  @if map-has-key($breakpoints, $breakpoint) {

    // Ausgabe
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  // Wenn ihr oben einen Wert eingetragen habt wird hier gecheckt ob ihr einen Einheit angehängt habt. Falls nicht wird px genutzt.
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    // Ausgabe
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}