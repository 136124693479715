.quote {
   color: $c2; 
   text-align: center;
   font-weight: 700;
   font-size: 20px;
   transition: all .3s ease-in-out;
   padding: 0 14px;

   @include breakpoint(tablet) {
    font-size: 30px;
   }

   @include breakpoint(desktop) {
    font-size: 40px;
   }

   span {
    display: block;
    font-weight: 400;
    margin-bottom: $gap_1;
   }
}

.btn_dark, button {
    background: $c1;
    color: $light;
    border: none;
    padding: 16px 22px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        background-color: $c2;
        transition: .2s ease-in-out;
    }
}

.stoerer {
    position: absolute;
    bottom: -12%;
    right: 5%;
    z-index: 2;
    background: $c2;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: $light;
    flex-direction: column;
    font-size: 1.2rem;
    line-height: normal;
    transition: all .2s ease-in-out;
    text-align: center;

    @include breakpoint(mobile_l) {
        right: 16px;
    }

    @include breakpoint(tablet) {
        width: 140px;
        height: 140px;
        bottom: -13%;
        font-size: 1.3rem;
    }

    @include breakpoint(laptop) {
        width: 180px;
        height: 180px;
        bottom: -15%;
        font-size: 1.5rem;
    }

    @include breakpoint(desktop-l) {
        width: 280px;
        height: 280px;
        bottom: -20%;
        right: -5%;
        font-size: 1.8rem;
    }

    span {
        display: block;
        font-weight: 400;
        font-size: 1rem;
        transition: all .2s ease-in-out;

        @include breakpoint(desktop-l) {
            font-size: 1.3rem;
        }
    }

    .kurse & {
        top: -90px;
        width: 210px;
        height: 210px;

        @include breakpoint(laptop) {
            width: 280px;
            height: 280px;
            top: -150px;
        }

        @include breakpoint(desktop) {
            top: -10%;
        }

        @include breakpoint(desktop-l) {
            top: -12%;
            width: 340px;
            height: 340px;
            right: -12%;
        }

        span {
            font-size: 14px;

            @include breakpoint(laptop) {
                font-size: 1.2rem;
            }
        }
    }
}