// FONTS //

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    /* Hier können Sie weitere Schriftarten-Formate hinzufügen */
    /* ../assets: url('../assets/fonts/Poppins.woff') format('woff'),
            url('../assets/fonts/Poppins.eot') format('eot'),
            url('../assets/fonts/Poppins.woff2') format('woff2'); */
  }
  @font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    /* Hier können Sie weitere Schriftarten-Formate hinzufügen */
    /* ../assets: url('../assets/fonts/Poppins.woff') format('woff'),
            url('../assets/fonts/Poppins.eot') format('eot'),
            url('../assets/fonts/Poppins.woff2') format('woff2'); */
  }
  @font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    /* Hier können Sie weitere Schriftarten-Formate hinzufügen */
    /* ../assets: url('../assets/fonts/Poppins.woff') format('woff'),
            url('../assets/fonts/Poppins.eot') format('eot'),
            url('../assets/fonts/Poppins.woff2') format('woff2'); */
  } 