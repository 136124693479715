$nav-width: 250px;
$nav_bg: $light;

.nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  width: $nav-width;
  position: fixed;
  right: 40px;
  background: $nav_bg;
  z-index: 20;

  &__list {
    padding: 20px;
    background-color: #fff;
    z-index: 10;
  }

  &__item {
    margin-bottom: 10px;
    padding: 10px;

    & a {
      position: relative;

      &:after {
        content: "";
        opacity: 0;
        width: 0;
      }

      &:hover::after {
        content: "";
        width: 100%;
        height: 3px;
        position: absolute;
        background-color: $c2;
        bottom: -8px;
        left: 0;
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }


  }
}

.nav__button {
  position: absolute;
  right: 2%;
  top: 2%;
  width: 43px;
  height: 41px;
  border: none;
  cursor: pointer;
  z-index: 20;
  padding: 0;

  &__icon {
    display: block;
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: rgb(255, 255, 255);
    border-radius: 2px;
    right: 6px;
  }

  &__icon:nth-child(1) {
    top: 10px;
  }

  &__icon:nth-child(2) {
    top: 18px;
    width: 20px;
  }

  &__icon:nth-child(3) {
    top: 26px;
  }

  &--open .nav__button__icon:nth-child(1) {
    transform: rotate(-45deg);
    top: 14px;
    transition: transform .3s ease-in-out;
    width: 20px;
  }
  &--open .nav__button__icon:nth-child(2) {
    transform: rotate(45deg);
    top: 14px;
    transition: transform .3s ease-in-out;
    
  }
  &--open .nav__button__icon:nth-child(3) {
    transform: rotate(45deg);
    top: 14px;
    transition: transform .3s ease-in-out;
    position: fixed;
  }
}

.nav--open {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

// DESKTOP 

@include breakpoint(tablet) {

  .nav {
    position: absolute;
    top: 57px;
    transform: none;
    left: inherit;
    right: 2%;
    z-index: 3;
    display: flex;
    width: auto;
    height: 300px;
    display: none;

      &.nav--open {
        display: block;
      }

      &__list {
        padding: 16px;
      }

      &__item {
        margin-bottom: 16px;
        padding: 0;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        & a {
          &:after {
            content: "";
            opacity: 0;
            width: 0;
          }

          &:hover::after {
            content: "";
            width: 100%;
            height: 3px;
            position: absolute;
            background-color: $c2;
            bottom: -4px;
            left: 0;
            opacity: 1;
            transition: all 0.2s ease-in-out;
          }
        }
      }
  }
}

// @include breakpoint(desktop) {
//   .nav {
//     right: 10%;
//   }
// }

.nav_scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: $c3;
  z-index: 30;
  transition: height 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(-100%);
  transition: all .2s ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  &.nav--open {
    .nav__list_scroll {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
    
  }

  .nav__button--open {

    .nav__button__icon:first-child {
      width: 30px !important;
    }
    .nav__button__icon:last-child {
      width: 30px !important;
    }
  }

  .content_container {
    height: 100%;

    .nav__button_scroll {
      background-color: transparent;
      position: absolute;
      right: 16px;
      top: 14px;
      z-index: 20;

      .nav__button__icon {
        background-color: $c1;

        &:first-child {
          width: 40px;
        }
        &:last-child {
          width: 30px;
        }
      }
    }

    .nav__list_scroll {
      position: absolute;
      right: 0;
      background-color: $light;
      padding: 18px 24px 4px;
      opacity: 0;
      transform: translateY(-600px);
      transition: all .2s ease-in-out;
      z-index: 10;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  
    img {
      display: block;
      height: 100%;
      margin: 0;
      width: auto;
      max-height: 100%;
      margin-left: 16px;

      @include breakpoint(desktop) {
          margin-left: 0;
      }   
    }
  }
}