// Transitions 
.page-enter-active, .page-leave-active {
    transition: all 0.5s ease-in-out;
  }

.page-enter, .page-leave-to {
    opacity: 0;
}


// Container 

.container {
    padding: 0 4%;
    margin: 0 auto;
    position: relative;
    max-width: rem(1000px);

    @include breakpoint(fullhd) {
     padding: 0 1rem;
    }
}

.container-medium {
    padding: 0 4%;
    margin: 0 auto;
    position: relative;
    max-width: rem(900px);

    @include breakpoint(tablet) {
     padding: 0 1rem;   
    }
}

.container-small {
    padding: 0 4%;
    margin: 0 auto;
    position: relative;
    max-width: rem(700px);

    @include breakpoint(tablet) {
     padding: 0 1rem;   
    }
}

.content_container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    img {
        width: 100%;
        max-height: 700px;
        object-fit: cover;
        margin-top: 2rem;

        #about & {
            width: 100%;
            max-width: 860px;
            margin: 4rem auto 0;
            display: block;
            object-position: top;
        }
    }
}

.center {
    text-align: center;
}

// HEADER

header {
    transition: all .3s ease-in-out;
    max-width: 1400px;
    margin: 0 auto 5rem;

    &#home {
        position: relative;
    }

    .logo {
        position: absolute;
        margin: 0 auto;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        transition: width .3s ease-in-out;
        width: 200px;

        @include breakpoint(tablet) {
            width: 280px;
        }

        @include breakpoint(desktop) {
            width: 360px;
        }
        
    }
}

main {
    p {
        margin-bottom: 1.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .text_box {
        background: $light;
        max-width: 700px;
        margin: 0 auto $gap-1;
        text-align: center;
        padding: 2rem;
        position: relative;
        transform: translateY(-10%);
        margin-bottom: 2rem;

        @include breakpoint(tablet) {
            transform: translateY(-34%);
            margin-bottom: -3%;
        }

        #sub & {
            margin-bottom: 6rem;
            transform: translateY(0%);
        }
    }

    .teaser {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 20px;
        margin-top: -1rem;
        margin-bottom: 1rem;

        @include breakpoint(laptop) {
            margin: 0 auto 6rem;
        }

        .tease {
            text-align: center;
            font-size: rem(15px);
            cursor: pointer;
            color: $light;
            background: $c1;
            padding: 20px;
            max-width: 600px;
            display: block;
            margin: 0 auto;
            margin-bottom: 1rem;

            @include breakpoint(laptop) {
                margin-bottom: 2rem;
            }

            > p {
                font-weight: 700;
            }

            &:hover > p{
                color: $c2;
            }

            .text {
                max-height: 0;
                overflow: hidden;
                padding: 0;
                transition: max-height 0.3s ease-out, padding 0.3s ease-out;
            }

            &.open .text {
                max-height: 900px;
                padding: 0px 0 30px;
            }

            span {
                opacity: 1;
                transition: all .2s ease-in-out;
                margin-top: 10px;
                display: block;
            }

            &.open span {
                opacity: 0;
            }
        }
    }

    .kurse {
        display: flex;
        max-width: 1200px;
        margin: 8rem auto 4rem;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 20px;
        position: relative;
        padding-top: 100px;

        @include breakpoint(tablet) {
            padding: 0 40px;
            padding-top: 100px;
        }

        @include breakpoint(desktop-l) {
            margin-top: 2rem;
        }

        .kurs {
            background: $light;
            padding: 2rem;
            width: calc(100% - 4rem);
            text-align: center;
            transition: all .2s ease-in-out;
            margin-bottom: 2rem;
            position: relative;

            @include breakpoint(tablet) {
                width: calc(48% - 4rem);
                margin-bottom: 3rem;
                padding-bottom: 4rem;

                button{
                    position: absolute;
                    bottom:2rem;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    max-width: 200px;
                }
            }

            @include breakpoint(desktop) {
                width: calc(30% - 3rem);
            }

            &:hover {
                box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
                transform: scale(1.02);
            }

            h2 {
                margin-bottom: 20px;
                font-size: 22px;
                position: relative;
                display: inline-block;
            }

            .dot {
                &:before {
                    content: '';
                    background: $c2;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: -18px;
                    top: 4px;
                    border-radius: 50%;
                }
            }

            ul {
                li {
                    margin-bottom: 16px;
                }

                &:last-of-type {
                    margin-bottom: 60px;
                }

                &.preis {
                    font-size: 18px;

                    &:last-of-type {
                        margin-bottom: 40px;
                    }
                }
            }

            img {
                width: 100%;
            }
        }
    }

    .termine {
        background: $light;
        padding: 2rem 1rem 2rem 3rem;
        max-width: 420px;
        margin: 4rem auto 6rem;
        transition: all .2s ease-in-out;

        @include breakpoint(tablet) {
            padding: 2rem 4rem;
        }

        ul {
            li {
                position: relative;
                font-size: 18px;
                margin-bottom: 1rem;

                &:before {
                    content: '';
                    background: $c1;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    left: -20px;
                    border-radius: 50%;
                    top: 8px;
                }

                strong {
                    display: block;
                }
            }
        }
    }
}

// FOOTER 

footer {
    transition: all .3s ease-in-out;
    position: relative;
    border-radius: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    #kontakt {
        background: $c2;
        text-align: center;
        color: $light;
        padding: 3rem 16px;
        width: calc(100% - 32px);

        @include breakpoint(mobile) {
            padding: 6rem 16px;
        }

        h1 {
            color: $light;
            padding-top: 0;

            &:after {
                background: $light;
            }
        }

        a {
            color: $light;
            &:hover {
                color: $c1
            }
        }
    }

    iframe {
        width: 100%;
        height: rem(400px);
        display: block;
        
        @include breakpoint(tablet) {
            height: rem(600px);
        }
    }

    .nav-footer {
        text-align: center;
        background: $c1;
        color: $light;
        padding: 30px 0;

        ul {
            @include breakpoint(tablet) {
                display: flex;
                justify-content: center;
            }
        }

        li {
            padding: 8px;

            @include breakpoint(tablet) {
                margin-right: 2rem;
                margin: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                color: $light;
                text-transform: uppercase;
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 2px;

                &:hover {
                    color: $c2;
                    transition: all .2s ease-in-out;
                }
            }
        }
    }
}