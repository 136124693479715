.header_slider {
    min-height: 600px;
    height: 100vh;
    max-height: 800px;
    overflow: hidden;
    z-index: 1;
    position: relative;


    .swiper-slide {
        width: 100%;
        display: flex;
        justify-content: center;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
        }
    
        .quote {
            color: $light;
            font-weight: 700;
            font-size: rem(20px);
            line-height: 1.3;
            position: absolute;
            bottom: 19%;
            margin-bottom: 0;
            max-width: 1000px;

            @include breakpoint(tablet) {
                font-size: rem(40px);
            }

            @include breakpoint(desktop) {
                font-size: rem(45px);
            }
    
            span {
                display: block;
                font-weight: 400;
                margin-bottom: 0;
                scale: .7;
            }
        }
    }
    
    .slide_1 {
        background: url(../assets/images/header/slide_1.jpg) no-repeat;
        background-position: center bottom;
        background-size: cover;
    }
    .slide_2 {
        background: url(../assets/images/header/slide_2.jpg) no-repeat;
        background-position: center bottom;
        background-size: cover;
    }
    .slide_3 {
        background: url(../assets/images/header/slide_3.jpg) no-repeat;
        background-position: center bottom;
        background-size: cover;
    }
    .slide_4 {
        background: url(../assets/images/header/slide_4.jpg) no-repeat;
        background-position: center bottom;
        background-size: cover;
    }

    .swiper-button-next, .swiper-button-prev {
        background: none;
        bottom: 6%;
        top: inherit;
        transition: all .3s ease-in-out;
        right: 10%;
        z-index: 3;

        @include breakpoint(laptop) {
            bottom: 26%;
        }
       
        &:after {
            content: '>';
            font-family: $main-font;
            font-size: 40px;
            color: $light;
            transition: all .2s ease-in-out;
            font-weight: 700;

            @include breakpoint(tablet) {
                font-size: 100px;
            }
        }
    
        &:hover:after {
            color: $c1;
        }
    }
    
    .swiper-button-prev {   
        left: 10%;
    
    
        &:after {
            content: '<';
        }
    }
}

#referenzen {
    max-width: 1400px;
    margin: 8rem auto;
    position: relative;
    overflow: hidden;
    

    @include breakpoint(desktop) {
        padding: 0 5rem;
    }

    .referenzen_swiper {
        width: 100%;
        max-width: 600px;
        margin: 12rem auto 0;
        position: relative;

        .swiper-slide {
            transition: transform .2s ease-in-out;
            z-index: 2;

            .referenz {
                text-align: center;
                width: 90%;
                margin: 0 auto;
                

                img {
                    width: calc(100% - 16px);
                    border: 8px solid $c2;
                    margin-bottom: 20px;
                    max-height: 400px;
                    object-fit: cover;
                }

                .text_img {
                    width: calc(100% - 16px);
                    border: 8px solid $c2;
                    margin-bottom: 20px;
                    height: 300px;
                    object-fit: cover;
                    background: $light;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    font-weight: 700;
                }

                .text {
                    background: $light;
                    padding: 2rem;

                    strong {
                        font-size: 19px;
                    }
                }
            }
    
            &.swiper-slide-next, &.swiper-slide-prev {
                opacity: .3;
                z-index: 1;
            }
            &.swiper-slide-next  {
                transform: translateY(-80px) translateX(-100px);
            }
            &.swiper-slide-prev  {
                transform: translateY(-80px) translateX(100px);
            }
        }
    }

    .swiper-button-next, .swiper-button-prev {
    background: none;
    top: 38%;
    transition: all .3s ease-in-out;
    right: 16px;

    @include breakpoint(mobile) {
         top: 48%;
    }

    @include breakpoint(tablet) {
        top: 21%;
        right: -40px;
    }
   
    &:after {
        content: '>';
        font-family: $main-font;
        font-size: 90px;
        color: $c2;
        transition: all .1s ease-in-out;
        font-weight: 700;
    }

    &:hover:after {
        color: $c1;
    }
}

.swiper-button-prev {   
    left: 16px;

    @include breakpoint(tablet) {
        left: -40px;
    }

    &:after {
        content: '<';
    }
}
}



