@charset "UTF-8";
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  /* Hier können Sie weitere Schriftarten-Formate hinzufügen */
  /* ../assets: url('../assets/fonts/Poppins.woff') format('woff'),
          url('../assets/fonts/Poppins.eot') format('eot'),
          url('../assets/fonts/Poppins.woff2') format('woff2'); */
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  /* Hier können Sie weitere Schriftarten-Formate hinzufügen */
  /* ../assets: url('../assets/fonts/Poppins.woff') format('woff'),
          url('../assets/fonts/Poppins.eot') format('eot'),
          url('../assets/fonts/Poppins.woff2') format('woff2'); */
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  /* Hier können Sie weitere Schriftarten-Formate hinzufügen */
  /* ../assets: url('../assets/fonts/Poppins.woff') format('woff'),
          url('../assets/fonts/Poppins.eot') format('eot'),
          url('../assets/fonts/Poppins.woff2') format('woff2'); */
}
/* BREAKPOINT v.1.0 MS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  line-height: 1.4;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::selection {
  background-color: rgba(0, 66, 127, 0.9);
  color: white;
}

html {
  background-color: #fdf6d7;
  font-family: "Poppins", sans-serif;
  line-height: 1.4;
  scroll-behavior: smooth;
}

body {
  color: #00427f;
}
body strong {
  font-weight: 700;
  letter-spacing: 1px;
}
@media (min-width: 668px) {
  body p {
    font-size: rem(18px);
  }
}
body a {
  color: #00427f;
  font-weight: 600;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  font-family: "Poppins", sans-serif;
  color: #00427f;
  text-align: center;
  margin-bottom: 3rem;
  transition: font-size 0.3s ease-in-out;
  font-weight: 700;
  position: relative;
  padding-left: 14px;
  padding-right: 14px;
}
body h1 span, body h2 span, body h3 span, body h4 span, body h5 span, body h6 span {
  display: block;
  color: #00427f;
}
body h1 {
  font-size: rem(24px);
  padding-top: 2rem;
}
@media (min-width: 400px) {
  body h1 {
    font-size: rem(29px);
  }
}
@media (min-width: 668px) {
  body h1 {
    font-size: rem(44px);
  }
}
@media (min-width: 1280px) {
  body h1 {
    font-size: rem(55px);
  }
}
body h1:after {
  content: "";
  height: 6px;
  width: 100%;
  background: #00427f;
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  max-width: 280px;
  margin: 0 auto;
}
@media (min-width: 668px) {
  body h1:after {
    max-width: 380px;
    height: 10px;
  }
}
body h1 span {
  font-size: rem(17px);
}
@media (min-width: 668px) {
  body h1 span {
    line-height: rem(22px);
  }
}
@media (min-width: 1280px) {
  body h1 span {
    font-size: rem(24px);
  }
}
@media (min-width: 1680px) {
  body h1 span {
    line-height: rem(40px);
  }
}
@media (min-width: 1920px) {
  body h1 span {
    font-size: rem(26px);
    line-height: rem(50px);
  }
}

.nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  width: 250px;
  position: fixed;
  right: 40px;
  background: rgb(255, 255, 255);
  z-index: 20;
}
.nav__list {
  padding: 20px;
  background-color: #fff;
  z-index: 10;
}
.nav__item {
  margin-bottom: 10px;
  padding: 10px;
}
.nav__item a {
  position: relative;
}
.nav__item a:after {
  content: "";
  opacity: 0;
  width: 0;
}
.nav__item a:hover::after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  background-color: #ea6926;
  bottom: -8px;
  left: 0;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.nav__button {
  position: absolute;
  right: 2%;
  top: 2%;
  width: 43px;
  height: 41px;
  border: none;
  cursor: pointer;
  z-index: 20;
  padding: 0;
}
.nav__button__icon {
  display: block;
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  right: 6px;
}
.nav__button__icon:nth-child(1) {
  top: 10px;
}
.nav__button__icon:nth-child(2) {
  top: 18px;
  width: 20px;
}
.nav__button__icon:nth-child(3) {
  top: 26px;
}
.nav__button--open .nav__button__icon:nth-child(1) {
  transform: rotate(-45deg);
  top: 14px;
  transition: transform 0.3s ease-in-out;
  width: 20px;
}
.nav__button--open .nav__button__icon:nth-child(2) {
  transform: rotate(45deg);
  top: 14px;
  transition: transform 0.3s ease-in-out;
}
.nav__button--open .nav__button__icon:nth-child(3) {
  transform: rotate(45deg);
  top: 14px;
  transition: transform 0.3s ease-in-out;
  position: fixed;
}

.nav--open {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 668px) {
  .nav {
    position: absolute;
    top: 57px;
    transform: none;
    left: inherit;
    right: 2%;
    z-index: 3;
    display: flex;
    width: auto;
    height: 300px;
    display: none;
  }
  .nav.nav--open {
    display: block;
  }
  .nav__list {
    padding: 16px;
  }
  .nav__item {
    margin-bottom: 16px;
    padding: 0;
    margin-right: 20px;
  }
  .nav__item:last-child {
    margin-right: 0;
  }
  .nav__item a:after {
    content: "";
    opacity: 0;
    width: 0;
  }
  .nav__item a:hover::after {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background-color: #ea6926;
    bottom: -4px;
    left: 0;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
}
.nav_scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fdf6d7;
  z-index: 30;
  transition: height 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.2s ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.nav_scroll.show {
  opacity: 1;
  transform: translateY(0);
}
.nav_scroll.nav--open .nav__list_scroll {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
.nav_scroll .nav__button--open .nav__button__icon:first-child {
  width: 30px !important;
}
.nav_scroll .nav__button--open .nav__button__icon:last-child {
  width: 30px !important;
}
.nav_scroll .content_container {
  height: 100%;
}
.nav_scroll .content_container .nav__button_scroll {
  background-color: transparent;
  position: absolute;
  right: 16px;
  top: 14px;
  z-index: 20;
}
.nav_scroll .content_container .nav__button_scroll .nav__button__icon {
  background-color: #00427f;
}
.nav_scroll .content_container .nav__button_scroll .nav__button__icon:first-child {
  width: 40px;
}
.nav_scroll .content_container .nav__button_scroll .nav__button__icon:last-child {
  width: 30px;
}
.nav_scroll .content_container .nav__list_scroll {
  position: absolute;
  right: 0;
  background-color: rgb(255, 255, 255);
  padding: 18px 24px 4px;
  opacity: 0;
  transform: translateY(-600px);
  transition: all 0.2s ease-in-out;
  z-index: 10;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.nav_scroll .content_container img {
  display: block;
  height: 100%;
  margin: 0;
  width: auto;
  max-height: 100%;
  margin-left: 16px;
}
@media (min-width: 1280px) {
  .nav_scroll .content_container img {
    margin-left: 0;
  }
}

.header_slider {
  min-height: 600px;
  height: 100vh;
  max-height: 800px;
  overflow: hidden;
  z-index: 1;
  position: relative;
}
.header_slider .swiper-slide {
  width: 100%;
  display: flex;
  justify-content: center;
}
.header_slider .swiper-slide:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.header_slider .swiper-slide .quote {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: rem(20px);
  line-height: 1.3;
  position: absolute;
  bottom: 19%;
  margin-bottom: 0;
  max-width: 1000px;
}
@media (min-width: 668px) {
  .header_slider .swiper-slide .quote {
    font-size: rem(40px);
  }
}
@media (min-width: 1280px) {
  .header_slider .swiper-slide .quote {
    font-size: rem(45px);
  }
}
.header_slider .swiper-slide .quote span {
  display: block;
  font-weight: 400;
  margin-bottom: 0;
  scale: 0.7;
}
.header_slider .slide_1 {
  background: url(../assets/images/header/slide_1.jpg) no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.header_slider .slide_2 {
  background: url(../assets/images/header/slide_2.jpg) no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.header_slider .slide_3 {
  background: url(../assets/images/header/slide_3.jpg) no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.header_slider .slide_4 {
  background: url(../assets/images/header/slide_4.jpg) no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.header_slider .swiper-button-next, .header_slider .swiper-button-prev {
  background: none;
  bottom: 6%;
  top: inherit;
  transition: all 0.3s ease-in-out;
  right: 10%;
  z-index: 3;
}
@media (min-width: 1024px) {
  .header_slider .swiper-button-next, .header_slider .swiper-button-prev {
    bottom: 26%;
  }
}
.header_slider .swiper-button-next:after, .header_slider .swiper-button-prev:after {
  content: ">";
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  color: rgb(255, 255, 255);
  transition: all 0.2s ease-in-out;
  font-weight: 700;
}
@media (min-width: 668px) {
  .header_slider .swiper-button-next:after, .header_slider .swiper-button-prev:after {
    font-size: 100px;
  }
}
.header_slider .swiper-button-next:hover:after, .header_slider .swiper-button-prev:hover:after {
  color: #00427f;
}
.header_slider .swiper-button-prev {
  left: 10%;
}
.header_slider .swiper-button-prev:after {
  content: "<";
}

#referenzen {
  max-width: 1400px;
  margin: 8rem auto;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1280px) {
  #referenzen {
    padding: 0 5rem;
  }
}
#referenzen .referenzen_swiper {
  width: 100%;
  max-width: 600px;
  margin: 12rem auto 0;
  position: relative;
}
#referenzen .referenzen_swiper .swiper-slide {
  transition: transform 0.2s ease-in-out;
  z-index: 2;
}
#referenzen .referenzen_swiper .swiper-slide .referenz {
  text-align: center;
  width: 90%;
  margin: 0 auto;
}
#referenzen .referenzen_swiper .swiper-slide .referenz img {
  width: calc(100% - 16px);
  border: 8px solid #ea6926;
  margin-bottom: 20px;
  max-height: 400px;
  object-fit: cover;
}
#referenzen .referenzen_swiper .swiper-slide .referenz .text_img {
  width: calc(100% - 16px);
  border: 8px solid #ea6926;
  margin-bottom: 20px;
  height: 300px;
  object-fit: cover;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}
#referenzen .referenzen_swiper .swiper-slide .referenz .text {
  background: rgb(255, 255, 255);
  padding: 2rem;
}
#referenzen .referenzen_swiper .swiper-slide .referenz .text strong {
  font-size: 19px;
}
#referenzen .referenzen_swiper .swiper-slide.swiper-slide-next, #referenzen .referenzen_swiper .swiper-slide.swiper-slide-prev {
  opacity: 0.3;
  z-index: 1;
}
#referenzen .referenzen_swiper .swiper-slide.swiper-slide-next {
  transform: translateY(-80px) translateX(-100px);
}
#referenzen .referenzen_swiper .swiper-slide.swiper-slide-prev {
  transform: translateY(-80px) translateX(100px);
}
#referenzen .swiper-button-next, #referenzen .swiper-button-prev {
  background: none;
  top: 38%;
  transition: all 0.3s ease-in-out;
  right: 16px;
}
@media (min-width: 400px) {
  #referenzen .swiper-button-next, #referenzen .swiper-button-prev {
    top: 48%;
  }
}
@media (min-width: 668px) {
  #referenzen .swiper-button-next, #referenzen .swiper-button-prev {
    top: 21%;
    right: -40px;
  }
}
#referenzen .swiper-button-next:after, #referenzen .swiper-button-prev:after {
  content: ">";
  font-family: "Poppins", sans-serif;
  font-size: 90px;
  color: #ea6926;
  transition: all 0.1s ease-in-out;
  font-weight: 700;
}
#referenzen .swiper-button-next:hover:after, #referenzen .swiper-button-prev:hover:after {
  color: #00427f;
}
#referenzen .swiper-button-prev {
  left: 16px;
}
@media (min-width: 668px) {
  #referenzen .swiper-button-prev {
    left: -40px;
  }
}
#referenzen .swiper-button-prev:after {
  content: "<";
}

.page-enter-active, .page-leave-active {
  transition: all 0.5s ease-in-out;
}

.page-enter, .page-leave-to {
  opacity: 0;
}

.container {
  padding: 0 4%;
  margin: 0 auto;
  position: relative;
  max-width: rem(1000px);
}
@media (min-width: 1920px) {
  .container {
    padding: 0 1rem;
  }
}

.container-medium {
  padding: 0 4%;
  margin: 0 auto;
  position: relative;
  max-width: rem(900px);
}
@media (min-width: 668px) {
  .container-medium {
    padding: 0 1rem;
  }
}

.container-small {
  padding: 0 4%;
  margin: 0 auto;
  position: relative;
  max-width: rem(700px);
}
@media (min-width: 668px) {
  .container-small {
    padding: 0 1rem;
  }
}

.content_container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.content_container img {
  width: 100%;
  max-height: 700px;
  object-fit: cover;
  margin-top: 2rem;
}
#about .content_container img {
  width: 100%;
  max-width: 860px;
  margin: 4rem auto 0;
  display: block;
  object-position: top;
}

.center {
  text-align: center;
}

header {
  transition: all 0.3s ease-in-out;
  max-width: 1400px;
  margin: 0 auto 5rem;
}
header#home {
  position: relative;
}
header .logo {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: width 0.3s ease-in-out;
  width: 200px;
}
@media (min-width: 668px) {
  header .logo {
    width: 280px;
  }
}
@media (min-width: 1280px) {
  header .logo {
    width: 360px;
  }
}

main p {
  margin-bottom: 1.5rem;
}
main p:last-of-type {
  margin-bottom: 0;
}
main .text_box {
  background: rgb(255, 255, 255);
  max-width: 700px;
  margin: 0 auto 3rem;
  text-align: center;
  padding: 2rem;
  position: relative;
  transform: translateY(-10%);
  margin-bottom: 2rem;
}
@media (min-width: 668px) {
  main .text_box {
    transform: translateY(-34%);
    margin-bottom: -3%;
  }
}
#sub main .text_box {
  margin-bottom: 6rem;
  transform: translateY(0%);
}
main .teaser {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: -1rem;
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  main .teaser {
    margin: 0 auto 6rem;
  }
}
main .teaser .tease {
  text-align: center;
  font-size: rem(15px);
  cursor: pointer;
  color: rgb(255, 255, 255);
  background: #00427f;
  padding: 20px;
  max-width: 600px;
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  main .teaser .tease {
    margin-bottom: 2rem;
  }
}
main .teaser .tease > p {
  font-weight: 700;
}
main .teaser .tease:hover > p {
  color: #ea6926;
}
main .teaser .tease .text {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}
main .teaser .tease.open .text {
  max-height: 900px;
  padding: 0px 0 30px;
}
main .teaser .tease span {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;
  display: block;
}
main .teaser .tease.open span {
  opacity: 0;
}
main .kurse {
  display: flex;
  max-width: 1200px;
  margin: 8rem auto 4rem;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  position: relative;
  padding-top: 100px;
}
@media (min-width: 668px) {
  main .kurse {
    padding: 0 40px;
    padding-top: 100px;
  }
}
@media (min-width: 1680px) {
  main .kurse {
    margin-top: 2rem;
  }
}
main .kurse .kurs {
  background: rgb(255, 255, 255);
  padding: 2rem;
  width: calc(100% - 4rem);
  text-align: center;
  transition: all 0.2s ease-in-out;
  margin-bottom: 2rem;
  position: relative;
}
@media (min-width: 668px) {
  main .kurse .kurs {
    width: calc(48% - 4rem);
    margin-bottom: 3rem;
    padding-bottom: 4rem;
  }
  main .kurse .kurs button {
    position: absolute;
    bottom: 2rem;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 200px;
  }
}
@media (min-width: 1280px) {
  main .kurse .kurs {
    width: calc(30% - 3rem);
  }
}
main .kurse .kurs:hover {
  box-shadow: rgba(17, 12, 46, 0.1) 0px 48px 100px 0px;
  transform: scale(1.02);
}
main .kurse .kurs h2 {
  margin-bottom: 20px;
  font-size: 22px;
  position: relative;
  display: inline-block;
}
main .kurse .kurs .dot:before {
  content: "";
  background: #ea6926;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -18px;
  top: 4px;
  border-radius: 50%;
}
main .kurse .kurs ul li {
  margin-bottom: 16px;
}
main .kurse .kurs ul:last-of-type {
  margin-bottom: 60px;
}
main .kurse .kurs ul.preis {
  font-size: 18px;
}
main .kurse .kurs ul.preis:last-of-type {
  margin-bottom: 40px;
}
main .kurse .kurs img {
  width: 100%;
}
main .termine {
  background: rgb(255, 255, 255);
  padding: 2rem 1rem 2rem 3rem;
  max-width: 420px;
  margin: 4rem auto 6rem;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 668px) {
  main .termine {
    padding: 2rem 4rem;
  }
}
main .termine ul li {
  position: relative;
  font-size: 18px;
  margin-bottom: 1rem;
}
main .termine ul li:before {
  content: "";
  background: #00427f;
  width: 8px;
  height: 8px;
  position: absolute;
  left: -20px;
  border-radius: 50%;
  top: 8px;
}
main .termine ul li strong {
  display: block;
}

footer {
  transition: all 0.3s ease-in-out;
  position: relative;
  border-radius: 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
footer #kontakt {
  background: #ea6926;
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 3rem 16px;
  width: calc(100% - 32px);
}
@media (min-width: 400px) {
  footer #kontakt {
    padding: 6rem 16px;
  }
}
footer #kontakt h1 {
  color: rgb(255, 255, 255);
  padding-top: 0;
}
footer #kontakt h1:after {
  background: rgb(255, 255, 255);
}
footer #kontakt a {
  color: rgb(255, 255, 255);
}
footer #kontakt a:hover {
  color: #00427f;
}
footer iframe {
  width: 100%;
  height: rem(400px);
  display: block;
}
@media (min-width: 668px) {
  footer iframe {
    height: rem(600px);
  }
}
footer .nav-footer {
  text-align: center;
  background: #00427f;
  color: rgb(255, 255, 255);
  padding: 30px 0;
}
@media (min-width: 668px) {
  footer .nav-footer ul {
    display: flex;
    justify-content: center;
  }
}
footer .nav-footer li {
  padding: 8px;
}
@media (min-width: 668px) {
  footer .nav-footer li {
    margin-right: 2rem;
    margin: 20px;
  }
  footer .nav-footer li:last-child {
    margin-right: 0;
  }
}
footer .nav-footer li a {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
}
footer .nav-footer li a:hover {
  color: #ea6926;
  transition: all 0.2s ease-in-out;
}

.quote {
  color: #ea6926;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  padding: 0 14px;
}
@media (min-width: 668px) {
  .quote {
    font-size: 30px;
  }
}
@media (min-width: 1280px) {
  .quote {
    font-size: 40px;
  }
}
.quote span {
  display: block;
  font-weight: 400;
  margin-bottom: 3rem;
}

.btn_dark, button {
  background: #00427f;
  color: rgb(255, 255, 255);
  border: none;
  padding: 16px 22px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}
.btn_dark:hover, button:hover {
  background-color: #ea6926;
  transition: 0.2s ease-in-out;
}

.stoerer {
  position: absolute;
  bottom: -12%;
  right: 5%;
  z-index: 2;
  background: #ea6926;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: rgb(255, 255, 255);
  flex-direction: column;
  font-size: 1.2rem;
  line-height: normal;
  transition: all 0.2s ease-in-out;
  text-align: center;
}
@media (min-width: 480px) {
  .stoerer {
    right: 16px;
  }
}
@media (min-width: 668px) {
  .stoerer {
    width: 140px;
    height: 140px;
    bottom: -13%;
    font-size: 1.3rem;
  }
}
@media (min-width: 1024px) {
  .stoerer {
    width: 180px;
    height: 180px;
    bottom: -15%;
    font-size: 1.5rem;
  }
}
@media (min-width: 1680px) {
  .stoerer {
    width: 280px;
    height: 280px;
    bottom: -20%;
    right: -5%;
    font-size: 1.8rem;
  }
}
.stoerer span {
  display: block;
  font-weight: 400;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 1680px) {
  .stoerer span {
    font-size: 1.3rem;
  }
}
.kurse .stoerer {
  top: -90px;
  width: 210px;
  height: 210px;
}
@media (min-width: 1024px) {
  .kurse .stoerer {
    width: 280px;
    height: 280px;
    top: -150px;
  }
}
@media (min-width: 1280px) {
  .kurse .stoerer {
    top: -10%;
  }
}
@media (min-width: 1680px) {
  .kurse .stoerer {
    top: -12%;
    width: 340px;
    height: 340px;
    right: -12%;
  }
}
.kurse .stoerer span {
  font-size: 14px;
}
@media (min-width: 1024px) {
  .kurse .stoerer span {
    font-size: 1.2rem;
  }
}

.sub_page {
  padding-top: 4rem;
}
@media (min-width: 668px) {
  .sub_page {
    padding-top: 14rem;
  }
}

#sub h3 {
  margin-bottom: 1rem;
  font-size: 24px;
}
#sub h4 {
  margin-bottom: 1rem;
}
#sub main p {
  margin-bottom: 2rem;
}
#sub main .text_box {
  max-width: 760px;
}